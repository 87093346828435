import React from "react";
//import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
//import BackgroundImage from "gatsby-background-image-es5";
import Layout from "../components/Layout";
import loadable from "@loadable/component";
import Specials from "../img/specials.svg";
import logo2 from "../img/buckhorn-supper-club.svg";
import { FaChevronRight } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
// import { FaPhoneAlt } from "react-icons/fa";
import AppsJSONData from "../global_data/appetizers.json";
import DinnersJSONData from "../global_data/dinners.json";
import KidsJSONData from "../global_data/kidsMenu.json";
import DrinksJSONData from "../global_data/drinks.json";
import DesertJSONData from "../global_data/deserts.json";
import { motion } from "framer-motion";
//import FsLightbox from "fslightbox-react";

const InfiniteCarousel = loadable(() => import("react-leaf-carousel"));

let theApps = AppsJSONData.appetizerItems.filter((f) => {
	return f.isSpecial;
});

let theDinners = DinnersJSONData.dinnerItems.filter((f) => {
	return f.isSpecial;
});

let theKids = KidsJSONData.kidsMenuItems.filter((f) => {
	return f.isSpecial;
});

let theDrinks = DrinksJSONData.drinkItems.filter((f) => {
	return f.isSpecial;
});

let theDeserts = DesertJSONData.desertItems.filter((f) => {
	return f.isSpecial;
});

const theSpecials = theApps.concat(theDinners, theKids, theDeserts, theDrinks);

const half = Math.ceil(theSpecials.length / 2);
const secondHalf = theSpecials.splice(0, half);
const firstHalf = theSpecials.splice(-half);

export const IndexPageTemplate = ({
	homeannoucementbanner,
	homeredbanner,
	image,
	eventfeaturedimage,
	secondbannerimage,
	thirdbannerimage,
	alerttitle,
	alertsubheading,
	title,
	heading,
	subheading,
	eventsubheading,
	subheading2,
	description,
	intro,
	slider,
	eventtitle,
	eventsub,
	eventbody,
	eventbtntext,
	eventbtnlink,
	eventimg,
	abouttitle,
	aboutsub,
	aboutbody,
	aboutbtntext,
	aboutbtnlink,
	aboutimg,
	card1img,
	card1title,
	card1body,
	card2img,
	card2title,
	card2body,
	card3img,
	card3title,
	card3body,
}) => {
	return (
		<div>
			{ homeannoucementbanner.showbanner ? <article class="message is-dark" style={{marginBottom: 0, border: 0, background: 'rgb(13 9 7)', borderRadius: 0}}>				
				<div class="message-body" style={{border: 0}}>
				<div style={{display: "flex"}}>
					<h4 style={{color: '#fff', margin: 0, marginRight: "20px"}}>
						{homeannoucementbanner.heading}
					</h4>
					{ homeannoucementbanner.link ? <div className="works-button" style={{marginLeft: "auto"}}>
						<a href={homeannoucementbanner.link} className="button is-primary shadow" target="_blank" rel="noopener noreferrer">
							<span>{homeannoucementbanner.linktext}</span>
							<span className="icon is-small">
								<FaChevronRight />
							</span>
						</a>
					</div> : null }
				</div>
				</div>
			</article> : null }
		{/*
			{homeannoucementbanner.link}
			{homeannoucementbanner.linktext}
			*/}
			<div
				className="full-width-image-container margin-top-0 mb0"
				style={{
					background: `url(${image.childImageSharp.fluid.src})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
				}}
			>
				{/*
			<BackgroundImage
				fluid={image.childImageSharp.fluid}
				className="full-width-image margin-top-0 home-banner"
			>
				*/}
				<div
					className="mx-auto relative"
					style={{
						backgroundSize: "cover",
						backgroundPosition: "top center",
						background:
							"linear-gradient(to right, rgba(0,0,0,0.6), rgba(0,0,0,0.1))",
						height: "100%",
						width: "100%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "column",
						padding: "100px 0",
					}}
				>
					<div
						style={{
							display: "flex",
							height: "150px",
							lineHeight: "1",
							alignItems: "center",
							textAlign: "center",
							justifyContent: "center",
							flexDirection: "column",
						}}
						className="home-logo-wrap"
					>
						<motion.div
							style={{ originY: 1 }}
							initial="hidden"
							animate="visible"
							variants={{
								hidden: {
									scale: 0.8,
									opacity: 0,
								},
								visible: {
									scale: 1,
									opacity: 1,
									transition: {
										delay: 0.4,
									},
								},
							}}
						>
							<img
								src={logo2}
								alt="Buckhorn Supper Club"
								className="mb0 svg-logo-home"
							/>
						</motion.div>
						<motion.div
							style={{ originY: 1 }}
							initial="hidden"
							animate="visible"
							variants={{
								hidden: {
									scale: 0.8,
									opacity: 0,
								},
								visible: {
									scale: 1,
									opacity: 1,
									transition: {
										delay: 0.6,
									},
								},
							}}
						>
							<p
								className="is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
								style={{
									backgroundColor: "#a62103",
									color: "white",
									lineHeight: "1",
									padding: "0.55em",
									fontStyle: "italic",
								}}
							>
								{subheading}
							</p>
						</motion.div>
					</div>
				</div>
				{/*
			</BackgroundImage>
				*/}
			</div>

			<section className="hours-reservations specials">
				<div className="container white section watch-video is-clearfix">
					<motion.div
						style={{ originY: 1 }}
						initial="hidden"
						animate="visible"
						variants={{
							hidden: {
								scale: 0.8,
								opacity: 0,
							},
							visible: {
								scale: 1,
								opacity: 1,
								transition: {
									delay: 0.8,
								},
							},
						}}
					>
						<h2
							className="white"
							style={{
								textAlign: "center",
								marginTop: 0,
								marginBottom: 0,
								lineHeight: 1,
							}}
						>
							{alerttitle}
						</h2>
						<h5>{alertsubheading}</h5>
					</motion.div>
					<motion.div
						style={{ originY: 1 }}
						initial="hidden"
						animate="visible"
						variants={{
							hidden: {
								scale: 0.8,
								opacity: 0,
							},
							visible: {
								scale: 1,
								opacity: 1,
								transition: {
									delay: 1,
								},
							},
						}}
					>
						<div
							style={{
								display: "flex",
								height: "30px",
								lineHeight: "1",
								alignItems: "center",
								justifyContent: "center",
								flexDirection: "column",
								padding: "0px",
								marginBottom: "30px",
							}}
						>
							<img
								src={Specials}
								alt="Buckhorn Supper Club Specials"
								className="mb0 svg-logo-menu"
							/>
						</div>
					</motion.div>
					<motion.div
						style={{ originY: 1 }}
						initial="hidden"
						animate="visible"
						variants={{
							hidden: {
								scale: 0.8,
								opacity: 0,
							},
							visible: {
								scale: 1,
								opacity: 1,
								transition: {
									delay: 1.2,
								},
							},
						}}
					>
						<hr className="style18" />

						<div className="columns">
							<div className="column menu-col is-6-desktop" data-aos="fade">
								<table className="specials-table">
									<tbody>
										{secondHalf.map((data, index) => {
											return (
												<>
													<tr
														key={`specials_left_${index}`}
														className="nameandprice"
													>
														<td className="dotStyle">
															<span className="menuStyle bgcover">
																{data.name}
															</span>
														</td>
														<td className="dotStyle">
															<span className="menuStyle bgcover">
																{data.price}
															</span>
														</td>
													</tr>
													<tr
														className="description"
														style={{ textAlign: "left" }}
													>
														<td colSpan={2}>{data.description}</td>
													</tr>
												</>
											);
										})}
									</tbody>
								</table>
							</div>
							<div
								className="column menu-col is-6-desktop has-text-centered"
								data-aos="fade-up"
							>
								<table className="specials-table">
									<tbody>
										{firstHalf.map((data, index) => {
											return (
												<>
													<tr
														key={`specials_right_${index}`}
														className="nameandprice"
													>
														<td className="dotStyle">
															<span className="menuStyle bgcover">
																{data.name}
															</span>
														</td>
														<td className="dotStyle">
															<span className="menuStyle bgcover">
																{data.price}
															</span>
														</td>
													</tr>
													<tr
														className="description"
														style={{ textAlign: "left" }}
													>
														<td colSpan={2}>{data.description}</td>
													</tr>
												</>
											);
										})}
									</tbody>
								</table>
							</div>
						</div>

						<hr className="style18 flip-vert" />
					</motion.div>
				</div>
			</section>

			<section
				id="services"
				className="section watch-video is-clearfix chair-bg"
			>
				<motion.div
					style={{ originY: 1 }}
					initial="hidden"
					animate="visible"
					variants={{
						hidden: {
							scale: 0.8,
							opacity: 0,
						},
						visible: {
							scale: 1,
							opacity: 1,
							transition: {
								delay: 1.4,
							},
						},
					}}
				>
					<div className="container">
						<div className="columns events-home-column location-columns">
							<div
								className="column is-6-desktop aos-init aos-animate"
								data-aos="fade"
							>
								<Img
									alt="The Buckhorn"
									className="photo"
									fluid={eventimg.childImageSharp.fluid}
								/>
							</div>
							<div
								className="column is-6-desktop aos-init aos-animate"
								data-aos="fade"
							>
								<div className="home-location">
									<div className="home-location-wrap">
										<h2 className="red mb0 lh1">{eventtitle}</h2>
										<h4
											dangerouslySetInnerHTML={{
												__html: eventsub,
											}}
											className="ital mt0 lh1"
										></h4>
										<p>{eventbody}</p>

										<div
											className="works-button home-events-locate-bottom"
											style={{ marginTop: "15px" }}
										>
											<Link
												to={eventbtnlink}
												className="button is-primary shadow"
											>
												<span>{eventbtntext}</span>
												<span className="icon is-small">
													<FaChevronRight />
												</span>
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="columns location-columns reverse-mobile-columns">
							<div
								className="column is-6-desktop aos-init aos-animate"
								data-aos="fade"
							>
								<div className="home-location">
									<div className="home-location-wrap">
										<h2 className="red mb0 lh1">{abouttitle}</h2>
										<h4
											dangerouslySetInnerHTML={{ __html: aboutsub }}
											className="ital mt0 lh1"
										></h4>
										<p>{aboutbody}</p>

										<div
											className="works-button home-events-locate-button"
											style={{ marginTop: "15px" }}
										>
											<Link
												to={aboutbtnlink}
												className="button is-primary shadow"
											>
												<span>{aboutbtntext}</span>
												<span className="icon is-small">
													<FaChevronRight />
												</span>
											</Link>
										</div>
									</div>
								</div>
							</div>
							<div
								className="column is-6-desktop has-text-centered aos-init aos-animate"
								data-aos="fade-up"
							>
								<div className="works-latest">
									<div className="works-latest-item">
										<Img
											alt="The Buckhorn"
											className="photo"
											fluid={aboutimg.childImageSharp.fluid}
										/>
									</div>
									{/* .works-latest-item */}
								</div>
							</div>
						</div>
					</div>
				</motion.div>
			</section>

			<div className="yellowbgpattern">
				<div className="section watch-video is-clearfix">
					<div className="container">
						<div className="columns">
							<div className="column is-4-desktop " data-aos="fade">
								<div className="hours-card photo">
									<Img fluid={card1img.childImageSharp.fluid} alt="award" />
									<div className="hours-card-content">
										<h6 className="red">{card1title}</h6>
										<hr />
										<p>{card1body}</p>
										<div className="works-button">
											<Link to={`/events`} className="button is-primary shadow">
												<span>View Events</span>
												<span className="icon is-small">
													<FaChevronRight />
												</span>
											</Link>
										</div>
									</div>
								</div>
							</div>
							<div className="column is-4-desktop " data-aos="fade">
								<div className="hours-card photo">
									<Img fluid={card2img.childImageSharp.fluid} alt="award" />
									<div className="hours-card-content">
										<h6 className="red">{card2title}</h6>
										<hr />
										<p>{card2body}</p>
										<div className="works-button">
											<a
												href="tel:16088682653"
												className="button is-primary shadow"
											>
												<span>Call Us</span>
												<span className="icon is-small">
													<FaPhoneAlt />
												</span>
											</a>
										</div>
									</div>
								</div>
							</div>
							<div className="column is-4-desktop " data-aos="fade">
								<div className="hours-card photo">
									<Img fluid={card3img.childImageSharp.fluid} alt="award" />
									<div className="hours-card-content">
										<h6 className="red">{card3title}</h6>
										<hr />
										<p>{card3body}</p>
										<div className="works-button">
											<a
												href="tel:16088682653"
												className="button is-primary shadow"
											>
												<span>Call Us</span>
												<span className="icon is-small">
													<FaPhoneAlt />
												</span>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="is-divider "></div>

			<section className="features">
				{typeof window !== "undefined" ? (
					<InfiniteCarousel
						breakpoints={[
							{
								breakpoint: 500,
								settings: {
									slidesToShow: 2,
									slidesToScroll: 2,
								},
							},
							{
								breakpoint: 768,
								settings: {
									slidesToShow: 3,
									slidesToScroll: 3,
								},
							},
						]}
						dots={false}
						showSides={true}
						sidesOpacity={1}
						sideSize={0.1}
						slidesToScroll={1}
						slidesToShow={4}
						scrollOnDevice={true}
						swipe={true}
						autoplay={true}
						autoplayScroll={1}
						autoplaySpeed={3000}
					>
						{slider.images.map((data, index) => {
							return (
								<div key={`slider_items_${data.image.childImageSharp.fluid}`}>
									<Img
										fluid={data.image.childImageSharp.fluid}
										alt={data.text}
									/>
								</div>
							);
						})}
					</InfiniteCarousel>
				) : null}
			</section>
		</div>
	);
};

IndexPageTemplate.propTypes = {
	image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	secondbannerimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	eventfeaturedimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	thirdbannerimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	bodySection: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	cards: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	title: PropTypes.string,
	heading: PropTypes.string,
	alerttitle: PropTypes.string,
	alertsubheading: PropTypes.string,
	subheading: PropTypes.string,
	homeredbanner: PropTypes.object,
	eventsubheading: PropTypes.string,
	subheading2: PropTypes.string,
	description: PropTypes.string,
	intro: PropTypes.shape({
		blurbs: PropTypes.array,
	}),
	slider: PropTypes.object,
};

const IndexPage = ({ data }) => {
	const { frontmatter } = data.markdownRemark;

	return (
		<Layout>
			<IndexPageTemplate
				homeannoucementbanner={frontmatter.homeannoucementbanner}
				image={frontmatter.hometopbanner.image}
				subheading={frontmatter.hometopbanner.subheading}
				alerttitle={frontmatter.homeredbanner.title}
				alertsubheading={frontmatter.homeredbanner.subheading}
				eventfeaturedimage={frontmatter.eventfeaturedimage}
				secondbannerimage={frontmatter.secondbannerimage}
				thirdbannerimage={frontmatter.thirdbannerimage}
				title={frontmatter.title}
				heading={frontmatter.heading}
				eventsubheading={frontmatter.eventsubheading}
				subheading2={frontmatter.subheading2}
				description={frontmatter.description}
				intro={frontmatter.intro}
				slider={frontmatter.slider}
				homeredbanner={frontmatter.homeredbanner}
				eventtitle={frontmatter.bodySection.section1.title}
				eventsub={frontmatter.bodySection.section1.subtitle}
				eventbody={frontmatter.bodySection.section1.body}
				eventbtntext={frontmatter.bodySection.section1.linktext}
				eventbtnlink={frontmatter.bodySection.section1.link}
				eventimg={frontmatter.bodySection.section1.image}
				abouttitle={frontmatter.bodySection.section2.title}
				aboutsub={frontmatter.bodySection.section2.subtitle}
				aboutbody={frontmatter.bodySection.section2.body}
				aboutbtntext={frontmatter.bodySection.section2.linktext}
				aboutbtnlink={frontmatter.bodySection.section2.link}
				aboutimg={frontmatter.bodySection.section2.image}
				card1img={frontmatter.cards.card1.image}
				card1title={frontmatter.cards.card1.title}
				card1body={frontmatter.cards.card1.body}
				card2img={frontmatter.cards.card2.image}
				card2title={frontmatter.cards.card2.title}
				card2body={frontmatter.cards.card2.body}
				card3img={frontmatter.cards.card3.image}
				card3title={frontmatter.cards.card3.title}
				card3body={frontmatter.cards.card3.body}
			/>
		</Layout>
	);
};

IndexPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default IndexPage;

export const pageQuery = graphql`
	query IndexPageTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
			frontmatter {
				title
				homeannoucementbanner {
					heading
					link
					linktext
					showbanner
				}
				hometopbanner {
					image {
						childImageSharp {
							fluid(maxWidth: 2048, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
					subheading
				}
				homeredbanner {
					title
					subheading
				}
				eventfeaturedimage {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid
						}
					}
				}
				secondbannerimage {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid
						}
					}
				}
				thirdbannerimage {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid
						}
					}
				}
				heading
				eventsubheading
				subheading2
				description
				slider {
					images {
						image {
							childImageSharp {
								fluid(maxWidth: 2048, quality: 100) {
									...GatsbyImageSharpFluid
								}
							}
						}
						text
					}
				}
				bodySection {
					section1 {
						title
						subtitle
						body
						linktext
						link
						image {
							childImageSharp {
								fluid(maxWidth: 2048, quality: 100) {
									...GatsbyImageSharpFluid
								}
							}
						}
						alt
					}
					section2 {
						title
						subtitle
						body
						linktext
						link
						image {
							childImageSharp {
								fluid(maxWidth: 2048, quality: 100) {
									...GatsbyImageSharpFluid
								}
							}
						}
						alt
					}
				}
				cards {
					card1 {
						title
						body
						link
						image {
							childImageSharp {
								fluid(maxWidth: 2048, quality: 100) {
									...GatsbyImageSharpFluid
								}
							}
						}
						alt
					}
					card2 {
						title
						body
						link
						image {
							childImageSharp {
								fluid(maxWidth: 2048, quality: 100) {
									...GatsbyImageSharpFluid
								}
							}
						}
						alt
					}
					card3 {
						title
						body
						link
						image {
							childImageSharp {
								fluid(maxWidth: 2048, quality: 100) {
									...GatsbyImageSharpFluid
								}
							}
						}
						alt
					}
				}
			}
		}
	}
`;
